import '../styles/main.scss'

import React, { useCallback } from 'react'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { NextIntlClientProvider } from 'next-intl'
import Script from 'next/script'
import { SSRProvider } from 'react-bootstrap'
import { SlugProps } from './[[...slug]]'
import { Roboto_Flex } from 'next/font/google'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { NextAdapter } from 'next-query-params'
import { QueryParamProvider } from 'use-query-params'
import ErrorBoundary from '../components/ErrorBoundary'
import { trpc } from '../utils/trpc'

const roboto = Roboto_Flex({ subsets: ['latin'] })

declare const window: any

function MyApp({ Component, pageProps }: AppProps<SlugProps>) {
  const [queryClient] = React.useState(() => new QueryClient())

  const router = useRouter()
  const path = React.useRef<string>('')

  const pushPageViewData = (site: any, page: any) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ event: 'virtualPageview', site, page })
  }

  const reloadOTBanner = () => {
    if (window.OneTrust != null) {
      window.OneTrust.Init()
      setTimeout(() => {
        window.OneTrust.LoadBanner()
        const toggleDisplay = document.getElementsByClassName(
          'ot-sdk-show-settings',
        )
        for (let i = 0; i < toggleDisplay.length; i += 1) {
          toggleDisplay[i].addEventListener('click', event => {
            event.stopImmediatePropagation()
            window.OneTrust.ToggleInfoDisplay()
          })
        }
      }, 1000)
    }
  }

  const handleRouteChange = useCallback(() => {
    window.dataLayer = window.dataLayer || []
    if (!!pageProps) {
      if (path.current !== router.asPath) {
        const site = {
          environment:
            pageProps?.environment === 'prd' ? 'production' : 'staging',
          country: pageProps?.resource?.langcode.split('-')?.[1].toLowerCase(),
          language: pageProps?.resource?.langcode.split('-')?.[0],
        }
        const page = {
          contentType: pageProps?.resource?.type
            .replace('node--', '')
            .replace('_', ' '),
        }
        pushPageViewData(site, page)
        path.current = router.asPath
      }
    }
    reloadOTBanner()
  }, [pageProps, router.asPath])

  React.useEffect(() => {
    handleRouteChange()
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [handleRouteChange, router])

  return (
    <ErrorBoundary>
      <NextIntlClientProvider messages={pageProps.messages}>
        <main className={roboto.className}>
          <QueryClientProvider client={queryClient}>
            <SSRProvider>
              <QueryParamProvider adapter={NextAdapter}>
                <Head>
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                  />
                </Head>

                {process.env.APP_ENV === 'prd' ? (
                  <Script
                    id="gtm"
                    dangerouslySetInnerHTML={{
                      __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-WRVL98G');`,
                    }}
                    strategy="afterInteractive"
                  />
                ) : (
                  <Script
                    id="gtm"
                    dangerouslySetInnerHTML={{
                      __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=aAnhgR-p8HlWgq5Pa_WKZA&gtm_preview=env-3&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-WRVL98G');`,
                    }}
                    strategy="afterInteractive"
                  />
                )}

                <Component {...pageProps} />
              </QueryParamProvider>
            </SSRProvider>
          </QueryClientProvider>
        </main>
      </NextIntlClientProvider>
    </ErrorBoundary>
  )
}

export default trpc.withTRPC(MyApp)
